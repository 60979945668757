import React, { FunctionComponent, PropsWithChildren, useContext } from 'react';

import ServerData from '../serverData';
import { InitializedDomainContext } from '../InitializedDomainContext';
import { ValidationContext } from '../ValidationContext';

import DomainName from './DomainName';
import DomainCName from './DomainCName';
import Organization from './Organization';
import Classification from './Classification';
import CRMAccountID from './CRMAccountID';
import FormLine, { FormLineLabel } from './FormLine';
import PrivilegedContractSelector from './PrivilegedContractSelector';
import OptionalContractSelector from './OptionalContractSelector';
import SubmitButton from './SubmitButton';

const Component: FunctionComponent = () => {
  const { state: { classification } } = useContext(InitializedDomainContext);
  const { validate, state: { contractGroups }} = useContext(ValidationContext);

  const domainTypeContracts = window.serverData.contracts.filter(server => server.domain_type).map(contract => contract.key);
  const optionalContracts = window.serverData.contracts.filter(server => !server.domain_type).map(contract => contract.key);

  return <form>
    <FormLine label={<FormLineLabel name="Name:" htmlId="domain_name" />}>
      <DomainName />
    </FormLine>
    <DomainCName />
    <FormLine label={<FormLineLabel name="Organization:" htmlId="org_id" />}>
      <Organization />
    </FormLine>
    <Classification />
    {classification === 'active_customer' && <CRMAccountID />}

    <PrivilegedContractSelector key={'contract-group-domainType'} contracts={domainTypeContracts} group="domainType" />
    <OptionalContractSelector contracts={optionalContracts} />

    <SubmitButton />
  </form>;
};
export default Component;
