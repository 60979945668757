import React, { FunctionComponent, PropsWithChildren, useContext, useState } from 'react';
import { InitializedDomainContext } from '../InitializedDomainContext';
import { ValidationContext } from '../ValidationContext';
import AutoLinkedContractDescription from './AutoLinkedContractDescription';

interface Props {
  contracts: string[];
  defaultContract?: string;
  group: string;
}

const keyToData = (key?: string) => window.serverData.contracts.find(server => server.key === key)!;

const PrivilegedContractSelector: FunctionComponent<Props> = (props: Props) => {
  const { contracts, defaultContract, group } = props;

  const { dispatch, state: { contractsByGroup }} = useContext(InitializedDomainContext);
  const { validate, state: { contractGroups }} = useContext(ValidationContext);
  const [ current, setCurrent ] = useState(defaultContract);
  const selectedContract = keyToData(current);

  const options = contracts
    .map(keyToData)
    .map(contract => (
      <li key={contract.key}>
        <input
          id={`${group}-${contract.key}`}
          type="radio"
          name={group}
          checked={contract.key === current}
          onChange={() => {
            setCurrent(contract.key);
            dispatch({ key: 'contractsByGroup', value: { ...contractsByGroup, [group]: contract.key }});
            validate('contractGroups', { ...contractGroups, [group]: true });
          }}/>
        <label htmlFor={`${group}-${contract.key}`}>{contract.name}</label>
      </li>)
    );

  return (
    <div className="contract-selector">
      <ul>
        {options}
      </ul>
      <AutoLinkedContractDescription description={selectedContract?.description} />
    </div>
  );
};
export default PrivilegedContractSelector;
