import React, { FunctionComponent, useContext } from 'react';
import { InitializedDomainContext } from '../InitializedDomainContext';

const Component: FunctionComponent = () => {
  const { dispatch } = useContext(InitializedDomainContext);
  const options = window.serverData.orgs.map(org => <option key={org.id} value={org.id}>{org.name}</option>);
  return (
    <select
      id="org_id"
      name="domain[organizationId]"
      defaultValue={window.serverData.currentOrg}
      onChange={(evt) => dispatch({ key: 'organizationId', value: evt.currentTarget.value })}
    >{options}</select>
  );
};
export default Component;
