import React, { FunctionComponent, createContext, useReducer } from 'react';
import { noop } from 'lodash';

export enum DomainClassificationCoreKey {
  Development = 'development',
  Test = 'test',
  Demo = 'demo',
  ActiveCustomer = 'active_customer',
  ChurnedCustomer = 'churned_customer',
  UnknownCustomer = 'unknown_customer',
  Unknown = 'unknown'
}

export interface InitializedDomain {
  domainName: string;
  domainCname: string;
  organizationId: number;
  classification: DomainClassificationCoreKey;
  contractsByGroup: { [groupKey: string]: string | string[] };
  crmAccountId: string | null;
}

const defaultInitializedDomain = {
  domainName: '',
  domainCname: '',
  organizationId: window.serverData.currentOrg,
  classification: DomainClassificationCoreKey.Development,
  contractsByGroup: { enforced: [ 'initialization' ] },
  crmAccountId: null
};

interface Context {
  state: InitializedDomain;
  // TODO: value: any sucks and should be better but I am lazy and don't want to write overloads for every key.
  dispatch: React.Dispatch<{ key: string; value: any; }>;
}

export const InitializedDomainContext = createContext<Context>({ state: defaultInitializedDomain, dispatch: noop });
const reducer = (state: InitializedDomain, action: { key: string; value: any }): InitializedDomain => {
  console.log('payload', state, action);
  return ({ ...state, [action.key]: action.value });
};


const Provider: FunctionComponent = ({ children }) => {
  const [ state, dispatch ] = useReducer<typeof reducer>(reducer, defaultInitializedDomain);

  return <InitializedDomainContext.Provider value={{ state, dispatch }}>
    {children}
  </InitializedDomainContext.Provider>;
};

export default Provider;
