import React, { FunctionComponent, useContext } from 'react';
import { InitializedDomainContext } from '../InitializedDomainContext';
import { ValidationContext } from '../ValidationContext';
import FormLine, { FormLineLabel } from './FormLine';

const formLabel = <FormLineLabel name="CRM Account ID:" htmlId="crm_account_id" />;

const crmAccountIdRegex = /^[a-f0-9]{8}-(?:[a-f0-9]{4}-){3}[a-f0-9]{12}$/i;
const invalidCrmAccountId = <div className="error explanation">This is an invalid CRM account ID.</div>;

const Component: FunctionComponent = () => {
  const { dispatch, state: { domainCname, crmAccountId }} = useContext(InitializedDomainContext);
  const { validate } = useContext(ValidationContext);

  const valid = !crmAccountId || crmAccountIdRegex.test(crmAccountId);
  const link = crmAccountId && valid && <div className="explanation">Visit the CRM for this account <a target="_blank" href={'https://tylertech.crm.dynamics.com/main.aspx?etc=1&id=%7bENTITY_ID%7d&pagetype=entityrecord'.replace('ENTITY_ID', crmAccountId)}>here</a> to verify it is correct.</div>;

  return (
    <FormLine label={formLabel}>
      <input
        id="crm_account_id"
        type="text"
        name="domain[crmAccountId]"
        onChange={(evt) => {
          const newEntityId = evt.currentTarget.value.trim();
          dispatch({ key: 'crmAccountId', value: newEntityId });
          validate('crmAccountId', crmAccountIdRegex.test(newEntityId));
        }}
        placeholder="12345678-ABCD-BCDE-CDEF-CAFE0987FACE" />
      {!valid && invalidCrmAccountId}
      {valid && link}
    </FormLine>
  );
};
export default Component;
