import React from 'react';
import ReactDOM from 'react-dom';

import DomainCreator from './components/DomainCreator';
import Provider from './InitializedDomainContext';
import Validator from './ValidationContext';
import './domain-creator.scss';

ReactDOM.render(
  <Provider>
    <Validator>
      <DomainCreator />
    </Validator>
  </Provider>,
  document.querySelector('#domain-creator')
);
