import React, { FunctionComponent, PropsWithChildren, useContext, useState } from 'react';

import { InitializedDomainContext } from '../InitializedDomainContext';
import defaultContracts from '../defaultContracts';
import AutoLinkedContractDescription from './AutoLinkedContractDescription';

const keyToData = (key: string) => window.serverData.contracts.find(server => server.key === key)!;

interface Props {
  contracts: string[];
}

const Component: FunctionComponent<Props> = (props: Props) => {
  const { contracts } = props;
  const { dispatch, state: { contractsByGroup }} = useContext(InitializedDomainContext);
  const [ hovered, setHovered ] = useState<string | undefined>();

  const hoveredContract = hovered ? keyToData(hovered) : undefined;

  const options = contracts
    .filter(key => key !== 'initialization')
    .map(keyToData)
    .map(contract => (
      <option key={contract.key} onMouseOver={(evt) => setHovered(evt.currentTarget.value)} value={contract.key}>
        {contract.name}
      </option>)
    );

  const onChangeSelectedContracts = (stuff: HTMLCollectionOf<HTMLOptionElement>) => {
    const result = [];
    for (let i = 0; i < stuff.length; i++) {
      result.push(stuff.item(i)!.value);
    }
    dispatch({ key: 'contractsByGroup', value: { ...contractsByGroup, '__optional': result }});
  };

  return (
    <div className="contract-selector optional-contract-selector">
      <div className="contract-selector-left">
        <div className="helper">Use ⌘+Click to (un-)select multiple configuration-contracts.</div>
        <select
          multiple={true}
          defaultValue={defaultContracts}
          onChange={(evt) => onChangeSelectedContracts(evt.currentTarget.selectedOptions)}>
          {options}
        </select>
      </div>
      <AutoLinkedContractDescription description={hoveredContract?.description} />
    </div>
  );
};
export default Component;

