import React, { FunctionComponent, useContext } from 'react';
import { InitializedDomainContext } from '../InitializedDomainContext';
import { ValidationContext } from '../ValidationContext';

const Component: FunctionComponent = () => {
  const { dispatch } = useContext(InitializedDomainContext);
  const { validate } = useContext(ValidationContext);

  return (
    <input
      id="domain_name"
      type="text"
      name="domain[name]"
      onChange={(evt) => {
        dispatch({ key: 'domainName', value: evt.currentTarget.value });
        validate('domainName', evt.currentTarget.value.length > 0);
      }} />
  );
};

export default Component;

