import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import cx from 'classnames';
import { InitializedDomainContext } from '../InitializedDomainContext';
import { ValidationContext } from '../ValidationContext';
import FormLine, { FormLineLabel } from './FormLine';

interface Response {
  valid: boolean;
  reason: string;
  crmAccountIdRequired: boolean;
}

const useRailsValidation = (cname: string) => {
  const [lastCname, setLastCname] = useState<string>('');
  const [valid, setValid] = useState<Response>({ valid: false, reason: '', crmAccountIdRequired: false });
  const { dispatch, validate } = useContext(ValidationContext);

  const doFetch = async (): Promise<void> => {
    const response = await fetch(`/internal/domains/new/validate_cname.json?cname=${cname}`);
    if (response.ok) {
      const json = await response.json();
      setValid(json);
      validate('domainCname', json.valid);
      dispatch({ key: 'crmAccountIdRequired', value: json.crmAccountIdRequired });
    }
  };

  useEffect(() => {
    if (lastCname !== cname) {
      if (cname?.length > 0) {
        doFetch();
        setLastCname(cname);
      }
    }
  });

  return valid;
};

const formLabel = <FormLineLabel name="CName:" htmlId="domain_cname-foo" />;
const Component: FunctionComponent = () => {
  const { dispatch, state: { domainCname }} = useContext(InitializedDomainContext);
  const { valid, reason } = useRailsValidation(domainCname);
  const error = !valid && reason.length > 0;
  const onChange = debounce((value: string) => dispatch({ key: 'domainCname', value }), 500);

  return (
    <FormLine label={formLabel} className={cx({ error })}>
      <input
        id="domain_cname-foo"
        placeholder={window.serverData.cnamePlaceholder}
        type="text"
        name="domain[cName]"
        onChange={(evt) => onChange(evt.currentTarget.value)}/>
      {error && <div className="error explanation">{reason}</div>}
    </FormLine>
  );
};

export default Component;
