import React, { FunctionComponent, useContext } from 'react';

import ServerData from '../serverData';
import { InitializedDomainContext, DomainClassificationCoreKey } from '../InitializedDomainContext';
import { ValidationContext } from '../ValidationContext';
import FormLine, { FormLineLabel } from './FormLine';

const formLabel = <FormLineLabel name="Classification:" htmlId="classification" />;
const Component: FunctionComponent = () => {
  const { dispatch, state: { classification } } = useContext(InitializedDomainContext);
  const { validate } = useContext(ValidationContext);
  const onChange = (event: React.SyntheticEvent<HTMLSelectElement>) => {
    const coreKey = window.serverData.classifications.find(server => server.key === event.currentTarget.value)!.core_key;
    validate('crmAccountIdRequired', coreKey === DomainClassificationCoreKey.ActiveCustomer);
    dispatch({ key: 'classification', value: coreKey });
  };

  const current = window.serverData.classifications.find(server => server.core_key === classification)!;

  const options = window.serverData.classifications
    .filter(c => !['unknown', 'churned', 'unknown_customer'].includes(c.key))
    .map(c => <option key={c.key} value={c.key}>{c.selector}</option>);
  return (
    <FormLine label={formLabel}>
      <select id="classification" name="domain[classification]" onChange={onChange}>
        {options}
      </select>
      <div className="explanation">
        {current.explanation}
      </div>
    </FormLine>
  );
};
export default Component;
