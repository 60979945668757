import React, { FunctionComponent, useContext, useState } from 'react';
import Button, { VARIANTS } from 'common/components/Button';
import { fetchJsonWithDefaultHeaders } from 'common/http';
import { InitializedDomainContext } from '../InitializedDomainContext';
import { ValidationContext } from '../ValidationContext';

const onError = (setAfter: (value: JSX.Element) => void) => (e: { response: Response }) => {
  if (e.response) {
    const requestid = e.response.headers.get('X-Socrata-RequestId');
    setAfter(<div className="error quasi-flash">
      Something went wrong with post-creation steps. Request Id: <code>{requestid}</code>
    </div>);
  }
  return false;
};

const Component: FunctionComponent = () => {
  const [busy, setBusy] = useState(false);
  const [after, setAfter] = useState<JSX.Element | null>(null); // FIXME: This is a terrible name.
  const { state: { contractsByGroup, ...initializationData } } = useContext(InitializedDomainContext);
  const { state: valid } = useContext(ValidationContext);
  const { domainCname } = initializationData;

  const crmAccountIdValidIfRequired = !valid.crmAccountIdRequired || valid.crmAccountId;
  const contractGroupsFulfilled = Object.values(valid.contractGroups).every(x => x);
  const enabled = valid.domainName && valid.domainCname && crmAccountIdValidIfRequired && contractGroupsFulfilled;

  const submit = async () => {
    setBusy(true);
    const contractKeys = Object.values(contractsByGroup).flat();

    const success1 = await fetchJsonWithDefaultHeaders('/api/domains.json?method=createAndInitialize', {
      method: 'POST',
      credentials: 'same-origin',
      body: JSON.stringify({ ...initializationData, contractKeys })
    })
      .then(() => true)
      .catch(onError(setAfter));
    if (!success1) return;

    const success2 = await fetchJsonWithDefaultHeaders('/internal/domains/new/post_create', {
      method: 'PUT',
      credentials: 'same-origin',
      body: JSON.stringify({ cname: domainCname })
    })
      .then(() => true)
      .catch(onError(setAfter));
    if (!success2) return;

    setAfter(<div className="success quasi-flash">
      Redirecting to <a href={`/internal/domains/${domainCname}`}>{`/internal/domains/${domainCname}`}</a>
    </div>);
    window.location.assign(`/internal/domains/${domainCname}`);
  };

  return (<React.Fragment>
    <Button variant={VARIANTS.PRIMARY} busy={busy} disabled={busy || !enabled} onClick={submit}>
      Create
    </Button>
    {after}
  </React.Fragment>);
};
export default Component;
