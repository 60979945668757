import React, { FunctionComponent, PropsWithChildren } from 'react';

const FormLineLabel: FunctionComponent<PropsWithChildren<{ name: string; htmlId: string; }>> = ({ name, htmlId }) => (
  <label htmlFor={htmlId}>{name}</label>
);

const FormLine: FunctionComponent<PropsWithChildren<{ label: JSX.Element; className?: string }>> = ({ className, children, label }) => (
  <div className={`line ${className}`}>
    {label}
    {children}
  </div>
);

export { FormLineLabel };
export default FormLine;
